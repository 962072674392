import React from 'react';
import styled from 'styled-components';
import GatsbyImage from 'gatsby-image';
import { H3 } from '@increasecard/typed-components';
import { Grid as BaseGrid } from '../../components/Grid';
import { BackgroundBase } from '../../backgrounds/BackgroundBase';
import { Metric } from '../../components/Metric';
import { Container } from '../../components/Container';
import { MEDIUM, LARGE } from '../../breakpoints';
import { RoundedSquare } from '../../backgrounds/shapes/RoundedSquare';

const Wrapper = styled.div`
  padding: 88px 0;
  color: ${({ theme }) => theme.colorsNew.gray.white};
`;

const Grid = styled(BaseGrid)`
  align-items: center;
`;

const TextWrapper = styled.div`
  position: relative;
  z-index: 1;
`;
const Title = styled.h1`
  ${({ theme }) => theme.typography.H1}
  font-weight: bold;
  line-height: 40px;
  margin-bottom: 24px;
  @media (min-width: ${MEDIUM}) {
    ${({ theme }) => theme.typography.superHeader}
    line-height: 51px;
  }
`;

const Description = styled(H3).attrs({ weight: 'normal' })`
  @media (min-width: ${MEDIUM}) {
    padding-right: 96px;
  }
`;

const ImageWrapper = styled.div`
  max-width: calc(100% + 64px);
  margin-top: 32px;

  @media (min-width: ${MEDIUM}) {
    margin-top: 0;
    margin-left: -64px;
  }
`;

const MetricsWrapper = styled(Grid)`
  background: ${({ theme }) => theme.colorsNew.increase.dark};
  grid-gap: 16px;
  margin-top: 80px;

  @media (min-width: ${MEDIUM}) {
    grid-gap: 24px;
    grid-template-columns: repeat(8, 95px);
  }
  @media (min-width: ${LARGE}) {
    grid-template-columns: repeat(8, 115px);
  }
`;

const SquareOutline = styled(RoundedSquare).attrs({
  rotation: '-30deg',
  size: '304px',
})`
  border: 2px solid ${({ theme }) => theme.colorsNew.gray.white};
  position: absolute;
  bottom: calc(160px - 304px / 2);
  left: calc(40px - 304px / 2);
`;

export function ClientsHeroSection({ title, description, image, metrics }) {
  return (
    <BackgroundBase
      baseBackground={theme => theme.colorsNew.increase.dark}
      renderBackground={() => (
        <>
          <SquareOutline />
        </>
      )}
    >
      <Wrapper>
        <Container>
          <Grid>
            <TextWrapper className="grid-span-md-4 grid-span-sm-2">
              <Title>{title}</Title>
              <Description>{description}</Description>
            </TextWrapper>
            <ImageWrapper className="grid-span-md-4 grid-span-sm-2">
              <GatsbyImage {...image.imageSharp.childImageSharp} />
            </ImageWrapper>
          </Grid>
          <MetricsWrapper>
            {metrics.map(({ desktopColumns, id, ...metric }) => (
              <Metric
                className={`grid-span-md-${desktopColumns} grid-span-sm-2`}
                key={id}
                {...metric}
              />
            ))}
          </MetricsWrapper>
        </Container>
      </Wrapper>
    </BackgroundBase>
  );
}
