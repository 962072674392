import React from 'react';
import { Container } from '../../components/Container';
import styled from 'styled-components';
import { Grid as BaseGrid } from '../../components/Grid';
import { MEDIUM } from '../../breakpoints';
import GatsbyImage from 'gatsby-image';
import { BackgroundBase } from '../../backgrounds/BackgroundBase';
import { Label as LabelBase, H3 } from '@increasecard/typed-components';
import { RoundedSquare } from '../../backgrounds/shapes/RoundedSquare';
import { ResponsiveImage } from '../../components/ResponsiveImage';

const Wrapper = styled.div``;
const Grid = styled(BaseGrid)`
  @media (min-width: ${MEDIUM}) {
    grid-auto-flow: column dense;
  }
`;
const UpperWrapper = styled.div`
  background: linear-gradient(
    180deg,
    ${({ theme }) => theme.colorsNew.increase.dark} 0%,
    ${({ theme }) => theme.colorsNew.gray.black} 100%
  );
  padding: 48px 0 32px;
  @media (min-width: ${MEDIUM}) {
    padding: 96px 0 24px;
  }
`;
const LowerWrapper = styled.div`
  padding: 112px 0;
`;
const ImageWrapper = styled.div`
  margin-bottom: 32px;
  grid-column: span 2 / span 2;
  @media (min-width: ${MEDIUM}) {
    grid-column: 5 / span 4;
    margin-bottom: -80px;
  }
`;
const TextWrapper = styled.div`
  @media (min-width: ${MEDIUM}) {
    padding: 0 32px;
  }
`;
const Label = styled(LabelBase).attrs({ as: 'div' })`
  font-weight: bold;
  color: ${({ theme }) => theme.colorsNew.increase.accent};
`;

const Title = styled.h1`
  ${({ theme }) => theme.typography.H1}
  line-height: 40px;
  font-weight: normal;
  margin: 16px 0 0;
  @media (min-width: ${MEDIUM}) {
    ${({ theme }) => theme.typography.superHeader}
    margin: 32px 0;
    font-weight: normal;
    line-height: 51px;
  }
`;

const LogosWrapper = styled.div`
  opacity: 1;
  display: grid;
  align-items: center;
  justify-content: center;
  grid-gap: 32px;
  grid-template-rows: repeat(${({ items }) => Math.round(items / 2)}, 60px);
  grid-template-columns: repeat(2, 1fr);
  @media (min-width: ${MEDIUM}) {
    display: grid;
    align-items: center;
    justify-content: center;
    grid-gap: 40px;
    grid-template-rows: repeat(3, 60px);
    grid-template-columns: repeat(5, 112px);
    justify-content: start;
  }
`;

const BigSquare = styled(RoundedSquare).attrs({
  rotation: '21deg',
  bgColor: theme => theme.colorsNew.increase.light,
  size: { md: '572px', sm: '320px' },
})`
  position: absolute;
  right: calc(80px + -320px / 2);
  bottom: calc(-80px + -320px / 2);
  @media (min-width: ${MEDIUM}) {
    right: calc(180px + -572px / 2);
    bottom: calc(-572px / 2);
  }
`;

export function ClientsEnterpriseSection({
  name,
  title,
  image,
  testimonial,
  showcasedClients,
}) {
  return (
    <BackgroundBase
      baseBackground={theme => theme.colorsNew.gray.white}
      renderBackground={() => <BigSquare />}
    >
      <Wrapper>
        <UpperWrapper>
          <Container>
            <Grid>
              <ImageWrapper>
                <GatsbyImage {...image.imageSharp.childImageSharp} />
              </ImageWrapper>
              <TextWrapper className="grid-span-md-4 grid-span-sm-2">
                <Label>{name}</Label>
                <Title dangerouslySetInnerHTML={{ __html: title }} />
                <H3 weight="normal" style={{ marginBottom: '8px' }}>
                  {testimonial.text}
                </H3>
                <div>
                  <H3 as="span">{testimonial.authorName}</H3>
                  <br />
                  <LabelBase>{testimonial.authorBrand}</LabelBase>
                </div>
              </TextWrapper>
            </Grid>
          </Container>
        </UpperWrapper>
        <LowerWrapper>
          <Container>
            <LogosWrapper items={showcasedClients.length}>
              {showcasedClients.map(({ logo }) => (
                <div
                  style={{
                    height: '60px',
                    maxWidth: '140px',
                    margin: '0 auto',
                  }}
                >
                  <ResponsiveImage
                    {...logo.imageSharp.childImageSharp.fluid}
                    pictureStyle={{
                      display: 'flex',
                      height: '100%',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    style={{
                      maxHeight: '100%',
                      maxWidth: '100%',
                    }}
                  />
                </div>
              ))}
            </LogosWrapper>
          </Container>
        </LowerWrapper>
      </Wrapper>
    </BackgroundBase>
  );
}
