import React from 'react';
import { Container } from '../../components/Container';
import styled from 'styled-components';
import { Grid } from '../../components/Grid';
import { MEDIUM } from '../../breakpoints';
import GatsbyImage from 'gatsby-image';
import { BackgroundBase } from '../../backgrounds/BackgroundBase';
import { Label as LabelBase, H3 } from '@increasecard/typed-components';
import { RoundedSquare } from '../../backgrounds/shapes/RoundedSquare';
import { ResponsiveImage } from '../../components/ResponsiveImage';

const Wrapper = styled.div``;
const UpperWrapper = styled.div`
  background: ${({ theme }) => theme.colorsNew.increase.regular};
  padding: 48px 0 32px;
  @media (min-width: ${MEDIUM}) {
    padding: 96px 0 24px;
  }
`;
const LowerWrapper = styled.div`
  padding: 112px 0;
`;
const ImageWrapper = styled.div`
  margin-bottom: 32px;
  @media (min-width: ${MEDIUM}) {
    margin-bottom: -80px;
  }
`;
const TextWrapper = styled.div`
  @media (min-width: ${MEDIUM}) {
    padding: 0 32px;
  }
`;
const Label = styled(LabelBase).attrs({ as: 'div' })`
  font-weight: bold;
  color: ${({ theme }) => theme.colorsNew.increase.dark};
`;

const Title = styled.h1`
  ${({ theme }) => theme.typography.H1}
  line-height: 40px;
  font-weight: normal;
  margin: 16px 0 0;
  @media (min-width: ${MEDIUM}) {
    ${({ theme }) => theme.typography.superHeader}
    margin: 32px 0;
    font-weight: normal;
    line-height: 51px;
  }
`;

const LogosWrapper = styled.div`
  opacity: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  > * {
    flex-basis: calc(50% - 16px);
    margin-bottom: 16px;
  }
  @media (min-width: ${MEDIUM}) {
    display: grid;
    align-items: center;
    justify-content: center;
    grid-gap: 32px;
    grid-template-rows: 96px;
    grid-template-columns: repeat(5, auto);
    justify-content: end;
  }
`;

const LogoWrapper = styled.div``;
const SmallSquare = styled(RoundedSquare).attrs({
  rotation: '21deg',
  bgColor: theme => theme.colorsNew.increase.light,
  size: '160px',
})`
  position: absolute;
  bottom: calc(680px - 80px / 2);
  right: calc(-80px / 2);
  @media (min-width: ${MEDIUM}) {
    bottom: calc(340px - 80px / 2);
  }
`;
const SquareOutline = styled(RoundedSquare).attrs({
  size: '486px',
  rotation: '21deg',
})`
  border: 3px solid ${({ theme }) => theme.colorsNew.increase.light};
  position: absolute;
  bottom: calc(400px + -486px / 2);
  left: calc(-120px + -486px / 2);
  @media (min-width: ${MEDIUM}) {
    bottom: calc(-40px + -486px / 2);
    left: calc(120px + -486px / 2);
  }
`;

export function ClientsSaasSection({
  name,
  title,
  image,
  testimonial,
  showcasedClients,
}) {
  return (
    <BackgroundBase
      baseBackground={theme => theme.colorsNew.gray.white}
      renderBackground={() => (
        <>
          <SmallSquare />
          <SquareOutline />
        </>
      )}
    >
      <Wrapper>
        <UpperWrapper>
          <Container>
            <Grid>
              <ImageWrapper className="grid-span-md-4 grid-span-sm-2">
                <GatsbyImage {...image.imageSharp.childImageSharp} />
              </ImageWrapper>
              <TextWrapper className="grid-span-md-4 grid-span-sm-2">
                <Label>{name}</Label>
                <Title dangerouslySetInnerHTML={{ __html: title }} />
                <H3 weight="normal" style={{ marginBottom: '8px' }}>
                  {testimonial.text}
                </H3>
                <div>
                  <H3 as="span">{testimonial.authorName}</H3>
                  <br />
                  <LabelBase>{testimonial.authorBrand}</LabelBase>
                </div>
              </TextWrapper>
            </Grid>
          </Container>
        </UpperWrapper>
        <LowerWrapper>
          <Container>
            <LogosWrapper>
              {showcasedClients.map(({ logo }) => (
                <LogoWrapper>
                  <div
                    style={{
                      height: '96px',
                      maxWidth: '140px',
                      margin: '0 auto',
                    }}
                  >
                    <ResponsiveImage
                      {...logo.imageSharp.childImageSharp.fluid}
                      pictureStyle={{
                        display: 'flex',
                        height: '100%',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                      style={{
                        maxHeight: '100%',
                        maxWidth: '100%',
                      }}
                    />
                  </div>
                </LogoWrapper>
              ))}
            </LogosWrapper>
          </Container>
        </LowerWrapper>
      </Wrapper>
    </BackgroundBase>
  );
}
