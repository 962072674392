import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/seo';
import { Section } from '../components/Section';
import { ClientsHeroSection } from '../pageSections/clientes/ClientsHeroSection';
import { ClientsSaasSection } from '../pageSections/clientes/ClientsSaasSection';
import { ClientsEnterpriseSection } from '../pageSections/clientes/ClientsEnterpriseSection';
import { Layout } from '../layout/Layout';

const sectionMapping = {
  Strapi_ComponentPageSectionsClientsHero: ClientsHeroSection,
  Strapi_ComponentPageSectionsClientsSaasSection: ClientsSaasSection,
  Strapi_ComponentPageSectionsClientsEnterpriseSection: ClientsEnterpriseSection,
};

export default ({ data }) => {
  const { seoInfo, sections } = data.strapi.clientsPage;
  return (
    <Layout variant="increase-dark">
      <SEO
        title={seoInfo.pageTitle}
        description={seoInfo.pageDescription}
        keywords={seoInfo.keywords}
      />
      {sections.map(s => {
        return (
          <Section mapping={sectionMapping} key={`${s.type}${s.id}`} {...s} />
        );
      })}
    </Layout>
  );
};

export const query = graphql`
  query {
    strapi {
      clientsPage {
        seoInfo {
          pageDescription
          pageTitle
          keywords
        }

        sections {
          type: __typename
          ... on Strapi_ComponentPageSectionsClientsHero {
            id
            title
            description
            image {
              ...Image
            }
            metrics {
              id
              name
              type
              gaugeText
              value: content
              valueType
              desktopColumns
            }
          }

          ... on Strapi_ComponentPageSectionsClientsSaasSection {
            id
            name
            title
            image {
              ...Image
            }
            testimonial {
              text
              authorName
              authorBrand
            }
            showcasedClients {
              name
              logo {
                ...Image
              }
            }
          }

          ... on Strapi_ComponentPageSectionsClientsEnterpriseSection {
            id
            name
            title
            image {
              ...Image
            }
            testimonial {
              text
              authorName
              authorBrand
            }
            showcasedClients {
              logo {
                ...Image
              }
            }
          }
        }
      }
    }
  }
`;
